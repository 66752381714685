import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Config } from '../helpers/Config'



let config = Config.getConfig('staging')
Vue.prototype.$config = config


Vue.use(VueAxios, axios)

const token = localStorage.getItem('token')


axios.defaults.baseURL = config.api

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Cache-Control'] = 'no-cache, max-age="3600'
axios.defaults.headers.common['Authorization'] = "Bearer " + token

